import React from 'react';
import { Box } from '@mui/material'

const CijferVerantwoording = () => {
    return (
        <Box
            sx={{
                fontSize: 'lFontSize',
                px: 4,

                '& > a': {
                    color: 'secondary.contrastText',
                    fontWeight: 'bold',
                    textUnderlineOffset: '2px'
                }
            }}
        >
            De dagelijkse Coronacijfers worden hier getoond zodra het RIVM deze publiceert. Voor de volledige lijst met data kan <a target="_blank" rel="noreferrer" href="https://data.rivm.nl/covid-19/">deze</a> lijst geraadpleegd worden.
        </Box>
    )
};

export default CijferVerantwoording;